import './module.public.layout.css';
import { onCLS, onINP, onLCP } from 'web-vitals/attribution';
import Util from '../../js/app/common/util';
import Navbar from '../../js/app/common/navbar';
import Facebook from '../../js/app/facebook/facebook.js';

window.Util = Util;
window.Navbar = Navbar;
window.Facebook = Facebook;

function sendToGoogleAnalytics({ name, delta, value, id, attribution, rating }) {
    const eventParams = {
        value: delta,
        metric_id: id,
        metric_value: value,
        metric_delta: delta,
        metric_rating: rating,
    };

    switch (name) {
        case 'CLS':
            eventParams.debug_target = attribution.largestShiftTarget;
            break;
        case 'INP':
            eventParams.debug_target = attribution.interactionTarget;
            eventParams.debug_type = attribution.interactionType;
            eventParams.debug_time = attribution.interactionTime;
            eventParams.debug_load_state = attribution.loadState;
            eventParams.debug_interaction_delay = Math.round(attribution.inputDelay);
            eventParams.debug_processing_duration = Math.round(attribution.processingDuration);
            eventParams.debug_presentation_delay = Math.round(attribution.presentationDelay);

            // Handle LoAF scripts for additional INP attribution
            const loaf = attribution.longAnimationFrameEntries?.at(-1);
            if (loaf) {
                const script = loaf.scripts?.toSorted((a, b) => b.duration - a.duration)[0];
                if (script) {
                    eventParams.debug_script_invoker_type = script.invokerType;
                    eventParams.debug_script_invoker = script.invoker;
                    eventParams.debug_script_source_url = script.sourceURL;
                    eventParams.debug_script_source_char_position = script.sourceCharPosition;
                    eventParams.debug_script_source_function_name = script.sourceFunctionName;
                }
            }
            break;
        case 'LCP':
            eventParams.debug_target = attribution.element;
            break;
    }

    gtag('event', name, eventParams);
}

// Initialize performance metric monitoring
onCLS(sendToGoogleAnalytics);
onINP(sendToGoogleAnalytics);
onINP(console.log);
onLCP(sendToGoogleAnalytics);

// Bootstrap and Velocity libraries
import '../../lib/v3/bootstrap-toggle/js/bootstrap-toggle.min.js';
import '../../lib/v3/bootstrap-select/1.13.2/js/bootstrap-select.min.js';
import '../../lib/app/node_modules/velocity-animate';

// Initialize app modules on document ready
$(document).ready(() => {
    window.navbar = new Navbar();
    window.facebook = new Facebook();
});
